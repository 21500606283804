import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Config } from "../interfaces/config";
import { AccessKeyMode } from "../models/program/access-key-mode.enum";
import { ExtendedProgram, FeriproProgram } from "../models/program/feripro-program.interface";
import { AccessCode } from "../models/program/program-extras.interface";
import { Program } from "../models/program/program.interface";
import { Logger } from "../providers/logger";

import { AllAccessCodesService } from "./all-access-codes.service";
import { BackendCallService } from "./backend-call.service";
import { ConfigService } from "./config.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ProgramService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private config_data: Config;
  private all_access_codes: AccessCode[] = [];

  private current_program: BehaviorSubject<Program> = new BehaviorSubject(
    undefined
  );
  private programs: BehaviorSubject<Program[]> = new BehaviorSubject<Program[]>(
    []
  );
  private extended_programs: {[program_id: number]: ExtendedProgram} = {}

  constructor(
    private backend: BackendCallService,
    private config_service: ConfigService,
    private all_access_codes_service: AllAccessCodesService,
    private storage_service: StorageService
  ) {
    super();
    this.config_data = this.config_service.config;
    this.init(backend);
  }

  protected onReady(): Promise<void> {
    this.subscriptions.push(
      this.all_access_codes_service
        .get_all_access_codes$()
        .subscribe((all_ac) => (this.all_access_codes = all_ac))
    );

    return this.api_get_programs().then(async (programs) => {
      this.programs.next(programs);

      const visible_programs = this.get_all_programs();

      // set program if current_program not set already.
      // Use the previously saved one or the first available if none found.
      // Needed for e.g. reload in shopping cart.
      if (!this.get_current_program() && visible_programs.length) {
        const program_id = await this.storage_service
          .get<number>("program_id")
          .catch(() => undefined);
        const program =
          visible_programs.find((p) => p.program_id === program_id) ||
          visible_programs[0];
        await this.set_current_program(program);
      }
      this.set_ready();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_current_program$(): Observable<Program> {
    return this.current_program.asObservable();
  }

  public get_current_program(): Program {
    return this.current_program.getValue();
  }

  public async set_current_program(program: Program): Promise<void> {
    if (this.get_current_program() !== program) {
      // save for reload of page
      await this.storage_service.set("program_id", program.program_id);
      this.current_program.next(program);
    }
  }

  public get_all_programs$(): Observable<Program[]> {
    // listen for AccessCode changes too in case a program is visible to the user by AccessCode
    // (see program_access_only_with_access_code in config)
    return combineLatest([
      this.programs.asObservable(),
      this.all_access_codes_service.get_all_access_codes$(),
    ]).pipe(
      map(([programs]) =>
        programs.filter((program) => this.is_program_visible(program))
      )
    );
  }
  public get_all_programs(): Program[] {
    return this.programs
      .getValue()
      .filter((program) => this.is_program_visible(program));
  }

  /** subscribe only if you REALLY need to. Is necessary to set AccessCode that enables program access */
  public get_all_programs_including_invisibles$(): Observable<Program[]> {
    return this.programs.asObservable();
  }
  public get_all_programs_including_invisibles(): Program[] {
    return this.programs.getValue();
  }

  public get_program(program_id: number): Program {
    return this.get_all_programs().find(
      (program) => program.program_id === program_id
    );
  }

  public async get_extended_program(program_id: number): Promise<ExtendedProgram> {
    if (!this.extended_programs[program_id]) {
      const url = `${this.backend.get_feripro_backend_domain()}/api/programs/${
        program_id
      }/extended`;
  
      await this.backend
        .get<ExtendedProgram>(url)
        .then((extended_program) => this.extended_programs[program_id] = extended_program)
        .catch(err => Logger.error("error loading extended program", {error: err}));
    }
    return this.extended_programs[program_id];
  }

  private is_program_visible(program: Program): boolean {
    if (this.config_data.hide_programs.includes(program.program_id)) {
      return false;
    }

    if (
      !this.config_data.program_access_only_with_access_code ||
      program.access_key_mode !== AccessKeyMode.REQUIRED
    ) {
      return true;
    }
    return this.all_access_codes
      .map((code) => code.name)
      .includes(program.name);
  }

  /**
   * holt alle angelegten Progrmme und events der Programme (event_data)
   * used by app compo & login page
   */
  private api_get_programs(): Promise<Program[]> {
    const url = `${this.backend.get_feripro_backend_domain()}/api/programs`;
    const program_order = this.config_data.program_order;

    return this.backend
      .get<FeriproProgram[]>(url)
      .then((programs) => {
        // convert FeriproProgram to Program
        return (programs || []).map(
          (program) =>
            ({
              ...program,
              program_id: parseInt(program.url.split("/").pop(), 10), // Set the program id. It doesn't exist in feripro response
              start_registration_date: program.start_registration_date
                ? new Date(program.start_registration_date)
                : null,
              last_registration_date: program.last_registration_date
                ? new Date(program.last_registration_date)
                : null,
            } as Program)
        )
        // sort by program_order in config
        .sort((a, b) => {
          const index_a =  program_order.includes(a.program_id) ? program_order.indexOf(a.program_id) : Infinity;
          const index_b =  program_order.includes(b.program_id) ? program_order.indexOf(b.program_id) : Infinity;

          return index_a - index_b;
        });
      })
      .catch((err) => {
        Logger.error("Die Programme konnten nicht geladen werden.", {error: err});
        return [];
      });
  }
}
