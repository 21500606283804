import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Disability } from "../models/event/disability.enum";
import { Event } from "../models/event/event.interface";
import { FeriproEvent } from "../models/event/feripro-event.interface";
import { Timespan } from "../models/event/timespan.interface";
import { Program } from "../models/program/program.interface";
import { PricePipe } from "../pipes/price/price.pipe";
import { Logger } from "../providers/logger";

import { BackendCallService } from "./backend-call.service";
import { ConfigService } from "./config.service";
import { ProgramService } from "./program.service";

export enum BookingState {
  FREE = "freie Plätze",
  WAITINGLIST_ONLY = "Warteliste möglich",
  FULL = "ausgebucht",
}

@Injectable({
  providedIn: "root",
})
export class EventService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private events_by_program_id: BehaviorSubject<{
    [program_id: number]: Event[];
  }> = new BehaviorSubject<{ [program_id: number]: Event[] }>({});
  private events_of_current_program: BehaviorSubject<Event[]> =
    new BehaviorSubject<Event[]>([]);

  constructor(
    private config_service: ConfigService,
    private backend: BackendCallService,
    private program_service: ProgramService,
    private price_pipe: PricePipe,
  ) {
    super();
    this.init(backend, program_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.program_service.get_all_programs$().subscribe(async (programs) => {

        await this.load_events_of(programs);

        // assert that all have been loaded
        const current_program_ids = Object.keys(this.events_by_program_id.getValue()).map(program_id => parseInt(program_id, 10));
        const errored_programs = programs.filter(p => !current_program_ids.includes(p.program_id));
        if (errored_programs.length) {
          Logger.error("Error fetching events of programs", {programs, errored_programs, loaded_programs: this.events_by_program_id.getValue()});
        }

        // service is ready for action
        this.set_ready();
      }),

      combineLatest([
        this.is_ready$(),
        this.program_service.get_current_program$(),
        this.events_by_program_id,  // trigger if new events added after ready
      ]).pipe(
        filter(([ready, _]) => ready),
        map(([_, program]) => program)
      )
      .subscribe(program => {
        const events = this.get_events_of(program);
        this.events_of_current_program.next(events);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_events_of_current_program$(): Observable<Event[]> {
    return this.events_of_current_program.asObservable();
  }
  public get_events_of_current_program(): Event[] {
    return this.events_of_current_program.value;
  }

  public get_all_events$(): Observable<Event[]> {
    return this.events_by_program_id.asObservable().pipe(
      map((event_dict) => {
        return Object.values(event_dict).reduce(
          (acc, events) => [...acc, ...events],
          []
        );
      })
    );
  }
  public get_all_events(): Event[] {
    const event_dict = this.events_by_program_id.value;
    return Object.values(event_dict).reduce(
      (acc, events) => [...acc, ...events],
      []
    );
  }

  public get_events_of$(program: Program): Observable<Event[]> {
    return this.events_by_program_id
      .asObservable()
      .pipe(map((event_dict) => event_dict[program.program_id] || []));
  }
  public get_events_of(program: Program): Event[] {
    return program ? this.events_by_program_id.getValue()[program.program_id] || [] : [];
  }

  /**
   * liefert eine Veranstaltung anhand der event_id
   * used by this, home page, event page & router service
   */
  public get_event_by_id(event_id: number): Event {
    return this.get_all_events().find((event) => event.event_id === event_id);
  }

  /**
   * liefert das Programm in dem die Veranstaltung vorhanden ist
   */
  public get_program_on_event(event: Event): Program {
    return event ? this.get_program_on_event_id(event.event_id) : null;
  }

  /**
   * liefert das Programm anhand der event_id
   * used by wihslist page & top-navbar compo
   */
  public get_program_on_event_id(event_id: number): Program {
    // program_ids of all programs the event is in
    const program_ids = Object.entries(this.events_by_program_id.value)
      .filter(([_, events]) =>
        events.some((event) => event_id === event.event_id)
      )
      .map(([program_id]) => parseInt(program_id, 10));

      // one of the programs it is in or undefined if none found
      return program_ids
        .map(program_id => this.program_service.get_program(program_id))
        .find(program => program);
  }

  /**
   * 
   * @returns value null ^= unlimited places
   */
  public get_free_places(event: Event): {occupied: number, free: number, max: number, accesskey_category_restriction: boolean} {

    let occupied: number, max: number;

    const program = this.get_program_on_event_id(event.event_id);
    const category_names = this.config_service.config.accesskey_categories
      .filter(cat => cat.program_id === program.program_id)
      .map(cat => cat.name);

    // use all (base case)
    if (!category_names?.length || !event.accesskey_categories?.length) {
      occupied = event.registration_statistics.registration_amount_yes;
      max = event.max_participants !== undefined ? event.max_participants : null;

      return { occupied, free: max === null ? null : Math.max(max - occupied, 0), max, accesskey_category_restriction: false};
    }

    // use quota only (special case)
    occupied = 0;
    max = 0;

    event.accesskey_categories
      .filter(cat => category_names.includes(cat.name))
      .forEach(cat => {
        occupied += cat.used;
        max += cat.quota;
      });

    return { occupied, free: Math.max(max - occupied, 0), max, accesskey_category_restriction: true };
  }

  public get_booking_state(event: Event): BookingState {

    // consider displaying only for chosen categories (of config.accesskey_categories)
    const free_places = this.get_free_places(event);
    // no restriction or some places unoccupied
    if (free_places.max === null || free_places.free || (!free_places.accesskey_category_restriction && event.has_free_non_reserved_places)) {
      return BookingState.FREE;
    }
    // waiting list also full
    if (!free_places.free && event.registration_statistics.is_waiting_list_full) {
      return BookingState.FULL;
    }
    // waiting list is still possible
    return BookingState.WAITINGLIST_ONLY;
  }

  /**
   * If a program is new, ask backend for its events.
   * Save them in this.events_by_program_id for later reference.
   * @param programs: all programs you want to have events available for. It will remove all events of other programs, so don't use subsets unintentionally!
   */
  private load_events_of(programs: Program[]): Promise<void> {
    const old_events_by_program_id = this.events_by_program_id.getValue();
  
    // function to check if program is new
    const is_new = (program: Program): boolean => {
      const current_program_ids = Object.keys(old_events_by_program_id).map(program_id => parseInt(program_id, 10));

      return !current_program_ids.includes(program.program_id);
    }

    return Promise.allSettled(
      programs.map((program) =>
        is_new(program) ?
          this.api_get_events(program).then((events) => ({ program, events })) :  // fetch events from backend
          Promise.resolve({ program, events: this.get_events_of(program) })       // use already existing events
      )
    ).then((program_event_tuples) => {
      // at this point: program_event_tuples = [[program_id, Events[]], ...]

      const events_by_program_id: { [program_id: number]: Event[] } =
        program_event_tuples
          .filter((t) => t.status === "fulfilled")
          .map((t: PromiseFulfilledResult<{ program: Program, events: Event[] }>) => t.value)
          .reduce((acc, { program, events }) => {
            
            // if events === undefined, there has been an error fetching from backend (see this.api_get_events()).
            if (events === undefined) { return acc; }

            acc[program.program_id] = events;
            return acc;
          }, {});

      // form of {program_id: Events[]} dict
      this.events_by_program_id.next(events_by_program_id);
    });
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  // ***** communication with feripro start *****

  /**
   * holt alle Veranstaltungen des Programms
   * wird immer nach Holen der Programme aufgerufen
   * 
   * @return Event[] on success and undefined on error
   */
  private async api_get_events(program: Program): Promise<Event[]> {
    if (!program?.visibility.includes("events")) { return []; }

    const program_url = program.events;
    return this.backend
      .get<FeriproEvent[]>(`${program_url}?cached`)
      .then((events) => {
        // convert from FeriproEvent to Event
        return events.map((event) => this.parse_Event_from_FeriproEvent(event));
      })
      .catch((error) => {
        Logger.error("api_get_events error", {error});
        return undefined;
      });
  }

  // ***********************************
  // **** Backend API functions end ****
  // ***********************************

  /** convert FeriproEvent instance to one of the internally used Event type */
  private parse_Event_from_FeriproEvent(event: FeriproEvent): Event {
    const convert_into_date = (date: string) => date ? new Date(date) : null;

    const timespans: Timespan<Date>[] = event.timespans.map(({ start, end }) => ({
      start: convert_into_date(start),
      end: convert_into_date(end),
    }));

    const summary_span: Timespan<Date> = {
      start: timespans[0]?.start,
      end: timespans.slice(-1)[0]?.end,
    };

    return {
      ...event,
      first_registration_date: convert_into_date(event.first_registration_date),
      last_registration_date: convert_into_date(event.last_registration_date),
      start: convert_into_date(event.start),
      end: convert_into_date(event.end),
      display_normalpreis: (!event.hide_price || !!event.price) && !!this.price_pipe.transform(event.price),

      timespans,
      times: this.get_timespan_display(timespans),
      times_summary: this.get_timespan_display([summary_span])[0] || "",

      // TODO: delete if feripro migrated from string to string[]
      supported_disabilities: Object.values(Disability).reduce((acc, v) => {
        if (event.supported_disabilities.includes(v)) { acc.push(v); }
        return acc;
      }, []),
    } as Event;
  }

  private get_timespan_display(timespans: Timespan<Date>[]): string[] {
    if (!timespans) { return []; }

    const locale = new Intl.Locale("de-DE");
    const date_options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const time_options: Intl.DateTimeFormatOptions = {
      timeStyle: "short",
      hourCycle: "h24",
    };
    const formatDate = (date: Date) => date.toLocaleDateString(locale, date_options);
    const formatTime = (date: Date) => date.toLocaleTimeString(locale, time_options) + " Uhr";

    return timespans
      .filter(span => span.start && span.end)
      .map(span => {
        if (
          span.start.getDate() === span.end.getDate() &&
          span.start.getMonth() === span.end.getMonth() &&
          span.start.getFullYear() === span.end.getFullYear()
        ) {
          return `${formatDate(span.start)} ${formatTime(span.start)} - ${formatTime(span.end)}`;
        }
        return `${ formatDate(span.start) } ${formatTime(span.start)} - ${formatDate(span.end)} ${formatTime(span.end)}`;
      });
  }
}
